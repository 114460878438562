<template>
  <div class="pt-36 bg-gray-100 shadow-sm rounded-lg pb-20">
    <div style="max-width: 800px" class="mx-auto text-left">
      <h2 class="text-4xl font-bold text-gray-900 mb-2">
        Sua Política de Privacidade Gratuita
      </h2>
      <p class="mb-6">
        Sua política é válida para uso em um domínio; sinta-se à vontade para
        editá-lo como quiser. Por favor, reveja suas políticas antes de
        adicionar ao seu site. Não nos responsabilizamos pelos termos. Por
        favor, não redistribua
      </p>
    </div>
    <div class="doc shadow-2xl mx-auto rounded-lg" style="max-width: 800px">
      <nav class="flex flex-col sm:flex-row text-center text-sm">
        <button
          class="rounded-tl-2xl z-0 text-gray-800 w-full bg-white py-4 px-6 block focus:outline-none font-medium"
        >
          Política de Privacidade
        </button>
        <button
          class="text-gray-600 bg-transparent text-center w-full py-4 px-6 focus:outline-none w-100"
        >
          🔒 Termos de Uso
        </button>
        <button
          class="text-gray-600 bg-transparent text-center w-full py-4 px-6 focus:outline-none w-100"
        >
          🔒 Relatórios e Guias
        </button>
      </nav>
      <section
        oncontextmenu="return false"
        ondragstart="return false"
        onselectstart="return false"
        class="z-50 container mx-auto px-4 pdf-item py-12 rounded-b-xl"
      >
        <div
          style="max-width: 624px !important"
          class="mx-auto space-y-2 grid grid-cols-2"
        >
          <div class="text-sm leading-5">
            <a
              href="#"
              class="group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
            >
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span> Copiar link de visualização </span>
            </a>
          </div>
          <div class="flex flex-row text-sm leading-5">
            <a
              href="#"
              class="group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
            >
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span> Copiar texto em HTML </span>
            </a>
          </div>
          <div class="flex flex-row text-sm leading-5">
            <a
              href="#"
              class="group space-x-2 inline-flex items-center font-medium text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
            >
              <svg
                class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                  clip-rule="evenodd"
                />
              </svg>
              <span> Copiar texto </span>
            </a>
          </div>
          <div class="flex text-sm leading-5">
            <a
              href="#"
              class="group space-x-2 inline-flex items-center text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
            >
              <svg
                class="h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-in-out duration-150"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"
                />
              </svg>
              <span> Aprenda a implementar seu documento </span>
            </a>
          </div>
        </div>
        <div
          class="text-center my-6 bg-blue-100 text-xs w-100 mx-auto py-3 mb-6 border-et-500 rounded-md"
          style="max-width: 624px !important"
        >
          <b>Gostou da política gratuita?</b> Compre o pacote completo com 10%
          de desconto
          <div class="btn bg-et-500 ml-2 text-white text-xs btn-sm px-1 py-2">
            Cupom: LGPD10
          </div>
        </div>
        <div class="mx-auto prose text-justify">
          <h1
            class="text-left uppercase mb-4 text-gray-900 font-bold"
            style="font-size: 22px"
          >
            Política de Privacidade
          </h1>

          <p class="pdf-item">
            <b>CONTROLADORA DOS DADOS:</b> {{ nm_rz_social }} (“{{
              nm_fantasia
            }}”), inscrita no CNPJ sob o n.º {{ formatCNPJ(cd_cnpj) }},
            endereço: {{ nm_endereco_logradouro }}, n° {{ cd_endereco_numero }},
            {{ nm_endereco_complemento }}, {{ nm_endereco_bairro }},
            {{ nm_endereco_cidade }}, CEP {{ formatCEP(cd_endereco_cep) }}.
          </p>

          <p class="pdf-item">Olá, bem-vindo(a)!</p>

          <p class="pdf-item">
            Nós da {{ nm_fantasia }} valorizamos a privacidade e queremos
            contribuir para a proteção de dados pessoais. Por essa razão, a
            seguir apresentamos nossa Política de Privacidade, para que você
            saiba como fazemos uso de seus dados pessoais.
          </p>

          <h3>O que faz a {{ nm_fantasia }}?</h3>
          <p class="pdf-item">
            Atuamos principalmente na seguinte frente de negócio:
          </p>

          <p class="pdf-item">
            <b>{{ nm_atividade_principal }}</b>
          </p>

          <p class="pdf-item">{{ ds_atividade_principal }}</p>

          <h3>
            Como funciona o tratamento de dados por parte da {{ nm_fantasia }}?
          </h3>

          <p class="pdf-item">
            Como toda a empresa, para realizar nossas atividades, precisamos
            coletar e tratar algumas informações que são consideradas pela
            legislação como dados pessoais. Para deixá-lo informado acerca do
            modo como fazemos o tratamento desses dados, preparamos o quadro
            resumo a seguir, com as principais perguntas que você pode ter.
          </p>

          <p class="pdf-item">
            Caso queira obter maiores informações sobre alguns dos tópicos,
            clique em Leia Mais na seção correspondente abaixo.
          </p>

          <template v-if="is_coletado_dados_pessoais">
            <h3>Quais Dados Pessoais Coletamos?</h3>
            <p class="pdf-item">
              Em síntese, coletamos os seguintes dados pessoais, mas não
              restritos a:
            </p>

            <ul>
              <li v-if="is_coletado_nome">Nome;</li>
              <li v-if="is_coletado_dt_nascimento">Data de nascimento;</li>
              <li v-if="is_coletado_endereco_comercial">Endereço comercial;</li>
              <li v-if="is_coletado_email">E-mail</li>
              <li v-if="is_coletado_telefone">Número de telefone/celular;</li>
              <li v-if="is_coletado_info_pagamento">
                Informações de pagamento;
              </li>
              <li v-if="is_coletado_midias_sociais">
                Perfil de mídias sociais;
              </li>
              <li v-if="is_coletado_endereco_residencial">
                Endereço residencial.
              </li>
            </ul>

            <p class="pdf-item">
              Para além dessas situações, poderemos utilizar seus dados pessoais
              de forma anonimizada (ou seja, de maneira que não leve à
              identificação de ninguém), com a finalidade de produzir relatórios
              analíticos de inteligência de negócio, a fim de melhorar nossos
              serviços.
            </p>

            <h3>Para que utilizamos os Dados Pessoais?</h3>

            <p class="pdf-item">
              Em síntese, utilizamos os dados pessoais coletados para
              finalidades ligadas à prestação de nossos serviços e às empresas
              que nos contratam. Nesse contexto, os dados pessoais nos são úteis
              para:
            </p>

            <ul>
              <li v-if="is_utilizado_ux">
                Customizar a experiência do usuário;
              </li>
              <li v-if="is_utilizado_manu_adm">
                Manutenção e administração de registros;
              </li>
              <li v-if="is_utilizado_pubi_mark">Publicidade e marketing;</li>
              <li v-if="is_utilizado_acesso">Garantir o acesso ao site;</li>
              <li v-if="is_utilizado_analytics">
                Analytics e pesquisa de mercado;
              </li>
              <li v-if="is_utilizado_compliance">
                Compliance &amp; solução de conflitos;
              </li>
              <li v-if="is_utilizado_contatar">Contatar os usuários;</li>
              <li v-if="is_utilizado_concursos">Concursos e promoções;</li>
              <li v-if="is_utilizado_recrutamento">
                Recrutamento e seleção de pessoas.
              </li>
            </ul>
          </template>

          <template v-if="is_compartilhado_dados">
            <h3>Com quem compartilhamos os Dados Pessoais?</h3>

            <p class="pdf-item">
              Os dados pessoais podem ser compartilhados com alguns
              destinatários, quais sejam:
            </p>

            <p class="pdf-item" v-if="is_compartilhado_plat_dados">
              <b>Plataformas de dados:</b> alguns de nossos clientes podem
              utilizar plataformas de dados, para melhorar a eficiência e
              agilidade de seus serviços e comunicação com outros players de
              mercado, incluindo a {{ nm_rz_social }}. Nessas comunicações,
              novamente temos de compartilhar os identificadores que possuímos
              com as referidas plataformas.
            </p>

            <p
              class="pdf-item"
              v-if="is_compartilhado_conferencia_indentificadores"
            >
              <b>Parceiros de conferência de identificadores:</b> para melhorar
              a eficiência da associação dos nossos identificadores com outros,
              podemos ter de compartilhar com nossos parceiros os
              identificadores que designamos aos navegadores por meio de
              cookies, como por exemplo parceiros relacionadas a intermediação
              de pagamentos.
            </p>

            <p class="pdf-item" v-if="is_compartilhado_combate_fraudes">
              <b>Parceiros de combate a fraudes:</b> para permitir que os
              parceiros possam efetivamente detectar fraudes, temos de
              compartilhar alguns dados pessoais por eles indicados.
            </p>

            <p class="pdf-item" v-if="is_compartilhado_parceiro">
              <b>Parceiros de nossos clientes:</b> alguns clientes podem pedir
              que compartilhemos dados com seus parceiros, por meio métricas que
              exibimos a vocês por meio de nossos clientes, para propósitos
              compatíveis com os originalmente aplicáveis (por exemplo, para
              medir a eficiência de determinado produto listado).
            </p>

            <p class="pdf-item" v-if="is_compartilhado_autoridade_oficiais">
              <b>Autoridades públicas ou órgãos oficiais:</b> para cumprimento
              de obrigações legais a que estamos submetidos, podemos ter de vir
              a compartilhar dados com autoridades públicas ou órgãos oficiais,
              mediante solicitação ou previsão legal expressa.
            </p>
          </template>

          <template v-if="is_utilizado_cookie">
            <h3>Política de Cookie</h3>

            <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
              -
            </div>

            <div
              class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
            >
              -
            </div>

            <h4>Por que utilizamos Cookies?</h4>

            <div
              class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
            >
              -
            </div>

            <div
              class="mt-3 h-20 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
            >
              -
            </div>

            <div
              class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
            >
              -
            </div>

            <h4>Quais tipos de cookies são utilizados?</h4>

            <template v-if="is_utilizado_cookie_necessarios">
              <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
                -
              </div>
              <div
                class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
              >
                -
              </div>
            </template>

            <template v-if="is_utilizado_cookie_desempenho">
              <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
                -
              </div>
              <div
                class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
              >
                -
              </div>
            </template>

            <template v-if="is_utilizado_cookie_marketing">
              <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
                -
              </div>
              <div
                class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
              >
                -
              </div>
            </template>

            <template v-if="is_utilizado_cookie_rede_sociais">
              <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
                -
              </div>
              <div
                class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
              >
                -
              </div>
            </template>

            <template v-if="is_utilizado_cookie_auth">
              <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
                -
              </div>
              <div
                class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
              >
                -
              </div>
            </template>
          </template>

          <template v-if="is_protegido_dados">
            <h3>Como protegemos os Dados Pessoais?</h3>

            <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
              -
            </div>
            <div
              class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
            >
              -
            </div>
          </template>

          <h3>Por quanto tempo guardamos os Dados Pessoais?</h3>

          <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
            -
          </div>

          <div class="mt-3 bg-gray-400 w-full text-gray-400 rounded shadow-sm">
            -
          </div>
          <div
            class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
          >
            -
          </div>

          <h3>Quais são os direitos relacionados aos Dados Pessoais?</h3>

          <div class="bg-gray-400 w-full text-gray-400 rounded shadow-sm">
            -
          </div>

          <div class="mt-3 bg-gray-400 w-full text-gray-400 rounded shadow-sm">
            -
          </div>
          <div
            class="mt-3 h-32 bg-gray-400 w-full text-gray-400 rounded shadow-sm"
          >
            -
          </div>

          <p class="pdf-item">
            {{ nm_dpo }}<br />
            {{ cd_email_dpo }}<br />
            {{ cd_telefone_dpo }}
          </p>

          <p class="pdf-item">Será um prazer ajudar!</p>

          <template v-if="settings.settingsExtra.showPowered">
            <powered-by />
          </template>
        </div>
      </section>
    </div>
    <v-style>
      .doc .container { background:
      {{ settings.settingsExtra.backgroundColor }} }
    </v-style>
    <v-style>
      .prose p, .prose li { color: {{ settings.settingsBody.color }}!important;
      font-size: {{ settings.settingsBody.focused_size }}!important;
      font-family: {{ settings.settingsBody.focused_font }}!important }
    </v-style>
    <v-style>
      .prose h1 { color:
      {{ settings.settingsHeading.color }}!important; } .prose h2, .prose h3,
      .prose h3 span { color: {{ settings.settingsHeading.color }}!important;
      font-size: {{ settings.settingsHeading.focused_size }}!important;
      font-family: {{ settings.settingsHeading.focused_font }}!important }
    </v-style>
    <v-style>
      .prose a { color: {{ settings.settingsLink.color }}!important; font-size:
      {{ settings.settingsLink.focused_size }}!important; font-family:
      {{ settings.settingsLink.focused_font }}!important; }
    </v-style>
  </div>
</template>
<script>
import { functions } from '@/utils/functions';

export default {
  name: 'PoliticaPrivacidade',
  created() {},
  methods: {},
  mixins: [functions],
  props: {
    nm_rz_social: {
      type: String,
      default: '',
      required: false,
    },
    nm_fantasia: {
      type: String,
      default: '',
      required: false,
    },
    cd_cnpj: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_logradouro: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_numero: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_complemento: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_bairro: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_cidade: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_cep: {
      type: String,
      default: '',
      required: false,
    },
    nm_atividade_principal: {
      type: String,
      default: '',
      required: false,
    },
    ds_atividade_principal: {
      type: String,
      default: '',
      required: false,
    },
    is_coletado_dados_pessoais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_nome: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_dt_nascimento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_endereco_comercial: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_email: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_telefone: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_info_pagamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_midias_sociais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_endereco_residencial: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_ux: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_manu_adm: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_pubi_mark: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_acesso: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_analytics: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_compliance: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_contatar: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_concursos: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_recrutamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_plat_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_conferencia_indentificadores: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_combate_fraudes: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_parceiro: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_autoridade_oficiais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_politica_interna: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_plano_resposta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_ssl: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_treinamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    nm_dpo: {
      type: String,
      default: '',
      required: false,
    },
    cd_email_dpo: {
      type: String,
      default: '',
      required: false,
    },
    cd_telefone_dpo: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style>
.sidebar-box {
  max-height: 140px;
  position: relative;
  overflow: hidden;
}
.sidebar-box .read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 80px 0;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, #fff);
}
</style>
